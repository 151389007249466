import Navigation from '@components/landingpage/Navigation'
import Footer from '@components/landingpage/Footer'

import styles from './LandingPageContainer.module.scss'

const LandingPageContainer = (props) => (
  <>
    <div className={styles.wrapper}>
      <Navigation head={'NextStage Government Contracting CRM'} />
      {props.children}
      <Footer />
    </div>
  </>
)

export default LandingPageContainer
