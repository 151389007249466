import styles from './Footer.module.scss'
import Link from 'next/link'

const config = {
  'Privacy Policy': '/privacy',
  'Terms of Use': '/terms',
}

const Footer = () => (
  <div className="flexBoxCol" style={{ textAlign: 'center' }}>
    {Object.entries(config).map(([k, v]) => (
      <div key={k}>
        <Link href={v}>{k}</Link>
      </div>
    ))}
  </div>
)

export default Footer
