import useIsMobile from '@helpers/hooks/IsMobileHook'
import { ScheduleADemoCTAButton } from './ScheduleADemoCTAButton'
import { TryForFreeCTAButton } from './TryForFreeCTAButton'

export function CallToActionButton() {
  const isMobile = useIsMobile()
  return <ScheduleADemoCTAButton />

  // if (isMobile) {
  //   return <ScheduleADemoCTAButton />
  // } else {
  //   return <TryForFreeCTAButton />
  // }
}
