import bgsLogo from '@public/images/lp/customers/bgs.png'
import cognitiveLogo from '@public/images/lp/customers/cognitive.png'
import conceptSolutionsLogo from '@public/images/lp/customers/concept-solutions.png'
import defintiiveLogicLogo from '@public/images/lp/customers/definitive_logic.png'
import evokeLogo from '@public/images/lp/customers/evoke.svg'
import gseLogo from '@public/images/lp/customers/gse.svg'
import ierusLogo from '@public/images/lp/customers/ierus.png'
import infinityLabsLogo from '@public/images/lp/customers/infinity-labs.jpeg'
import itechLogo from '@public/images/lp/customers/itech.jpeg'
import metronomeLogo from '@public/images/lp/customers/metronome.svg'
import praescientLogo from '@public/images/lp/customers/praescient.png'
import rsc2Logo from '@public/images/lp/customers/rsc2.png'
import synergyLogo from '@public/images/lp/customers/synergy.svg'
import tspiLogo from '@public/images/lp/customers/tspi.png'
import tsymmetryLogo from '@public/images/lp/customers/tsymmetry_dark.png'
import zemitekLogo from '@public/images/lp/customers/zemitek.jpeg'
import Image, { StaticImageData } from 'next/image'
import styles from './CustomerLogos.module.scss'

const logos: [string, StaticImageData][] = [
  ['Definitive Logic', defintiiveLogicLogo],
  ['Metronome', metronomeLogo],
  ['Synergy BIS', synergyLogo],
  ['Boston Government Services', bgsLogo],
  ['Cognitive Medical Systems', cognitiveLogo],
  ['Concept Solutions', conceptSolutionsLogo],
  ['Evoke Consulting', evokeLogo],
  ['GS Engineering', gseLogo],
  ['IERUS', ierusLogo],
  ['iTech AG', itechLogo],
  ['InfinityLabs', infinityLabsLogo],
  ['RSC 2', rsc2Logo],
  ['Prasecient Analytics', praescientLogo],
  ['TSPi', tspiLogo],
  ['Tsymmetry', tsymmetryLogo],
  ['Zemitek', zemitekLogo],
]

export const CustomerLogos = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.header} style={{ textAlign: 'center' }}>
        Trusted by award-winning government contractors
      </h1>
      <div className="flexBoxCol">
        <div className={styles.row}>
          {logos.map(([company, logo]) => (
            <div key={`${company} Logo`} className={styles.logo}>
              <Image src={logo} layout="responsive" style={{ alignSelf: 'center' }} alt={`${company} Logo`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
