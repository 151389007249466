import { showDetailsStyles } from '@components/dropdown/Dropdown'
import styles from './FeatureGrid.module.scss'

const defaultFeatures = [
  {
    title: 'Premium Onboarding and Data Migration',
    description: 'Let us help you onboard your opportunities in less than 7 days.',
  },
  {
    title: 'Google and Microsoft SSO',
    description: 'Single Sign On with your Google or Microsoft 365 account.',
  },
  {
    title: 'Enterprise SSO',
    description:
      'Integrations with 25 IDPs, including Azure Government Cloud (GCC High) to provide secure authentication into NextStage.',
  },
  {
    title: 'Security and Encryption',
    description:
      'Build on Google Cloud FEDRamp High infrastructure, encrpyting data in flight and at-rest with a FIPS 140-2 encryption module',
  },
  {
    title: 'Role Based Access Control',
    description: "Administrators can control user's access to NextStage Workspaces.",
  },
  {
    title: 'Shared Workspaces for Joint Ventures',
    description: 'Manage Joint Venture Teams and Pipelines without sharing your entire pipeline.',
  },
]

type FeatureType = {
  title: string
  description: string
}

interface FeatureGridProps {
  features?: FeatureType[]
}

const Feature = ({ title, description }: FeatureType) => {
  return (
    <div className={styles.feature}>
      <h4 className={styles.featureTitle}>{title}</h4>
      <p className={styles.featureDescription}>{description}</p>
    </div>
  )
}

export const FeatureGrid = (props: FeatureGridProps) => {
  const { features = defaultFeatures } = props
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.headline}>Other features</h3>
      <div className={styles.grid}>
        {features.map((featureProps, index) => (
          <Feature key={`feature_${index}`} {...featureProps} />
        ))}
      </div>
    </div>
  )
}
