import Image from 'next/image'
import govWinLogo from '@public/images/lp/govwin_logo.jpeg'
import gsaEbuyLogo from '@public/images/lp/gsa_ebuy.webp'
import ciospLogo from '@public/images/lp/nitaac_ciosp3_logo.webp'
import fpdsLogo from '@public/images/lp/fpds_logo.webp'
import pipelineImg from '@public/images/lp/pipeline.webp'
import samLogo from '@public/images/lp/sam.svg'
import tridentImg from '@public/images/lp/trident.svg'

import styles from './HeroImage.module.scss'

export const HeroImage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.dataSources}>
        <div className={styles.source}>
          <Image priority src={samLogo} alt={'SAM.gov Logo'} />
        </div>
        <div className={styles.source}>
          <Image priority src={gsaEbuyLogo} alt={'Gsaebuy Logo'} />
        </div>
        <div className={styles.source}>
          <Image priority src={ciospLogo} alt={'NITAAC Logo'} />
        </div>
        <div className={styles.source}>
          <Image priority src={govWinLogo} alt={'GovWin Logo'} />
        </div>
        <div className={styles.source}>
          <Image priority src={fpdsLogo} alt={'FPDS Logo'} />
        </div>
      </div>

      <div className={styles.trident}>
        <Image priority src={tridentImg} />
      </div>
      <div className={styles.rightSection}>
        {/* <div className={styles.features}>
          <span className="subheader">Pipeline Management</span>
          <span className="subheader">Opportunity Management</span>
          <span className="subheader">Timeline and Resource Planning</span>
        </div> */}
        <div className={styles.productImage}>
          <Image priority layout="intrinsic" src={pipelineImg} quality={100} />
        </div>
      </div>
    </div>
  )
}
