import styles from './ProductFeature.module.scss'
import Asterisk from '@public/images/asterisk.png'
import Image, { StaticImageData } from 'next/image'
import _ from 'lodash'

export interface ProductFeatureProps {
  name: string
  headline: string
  descriptionItems: string[]
  imageSrc: StaticImageData
  imageSide?: 'left' | 'right'
}
export const ProductFeature = (props: ProductFeatureProps) => {
  const { name, headline, descriptionItems, imageSrc, imageSide } = props

  return (
    <div className={imageSide == 'left' ? styles.containerReversed : styles.container}>
      <div className={styles.textContainer}>
        <h4 className={styles.name}>{name}</h4>
        <h3 className={styles.headline}>{headline}</h3>
        {descriptionItems.map((descriptionItem, index) => (
          <div key={`${name}_${index}`} className={styles.descriptionContainer}>
            <div className={styles.bullet}>
              <Image src={Asterisk} alt={'*'} />
            </div>
            <p className={styles.descriptionItem}>{descriptionItem}</p>
          </div>
        ))}
      </div>
      <div className={styles.image}>
        <Image alt={`${headline} graphic`} src={imageSrc} />
      </div>
    </div>
  )
}
