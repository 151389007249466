import { Testimonial, TestimonialProps } from '@components/testimonial/Testimonial'
import style from './TestimonialSection.module.scss'
import KarenHarrisHeadshot from '@public/images/lp/customers/testimonials/karen_harris.webp'
import AdamByerHeadshot from '@public/images/lp/customers/testimonials/adam_byer.webp'
import PatMercadoHeadshot from '@public/images/lp/customers/testimonials/patricia_mercado.webp'
import MargotBlackardHeadshot from '@public/images/lp/customers/testimonials/margot_blackard.webp'

const testimonials: TestimonialProps[] = [
  {
    firstName: 'Adam',
    lastName: 'Byer',
    title: 'Chief Delivery Officer',
    company: 'iTech AG',
    content:
      'As a small business that relies on limited resource availability at times, we really needed a CRM that would automate and streamline opportunities and NextStage has exceeded our expectations.',
    headshotImageSource: AdamByerHeadshot,
    companyLogo: '',
  },
  {
    firstName: 'Karen',
    lastName: 'Harris',
    title: 'Vice President, Business Development',
    company: 'Boston Government Services',
    content:
      'We found the NextStage platform intuitive and easy to use, with the added benefit of an excellent search feature that seamlessly ties into external systems such as GovWin, GSA eBuy, and SAM.gov',
    headshotImageSource: KarenHarrisHeadshot,
    companyLogo: '',
  },
  {
    firstName: 'Pat',
    lastName: 'Mercado',
    title: 'Vice President, Proposal Operations',
    company: 'Metronome',
    content:
      'It is so much easier to manage our pipeline and visualize our progress towards business goals with NextStage.  The links to external sources of GovCon opportunity information (e.g., SAM.gov) saves a lot of time entering and updating the critical fields such as RFP due dates.',
    headshotImageSource: PatMercadoHeadshot,
    companyLogo: '',
  },
  {
    firstName: 'Margot',
    lastName: 'Blackard',
    title: 'Chief Strategy Officer',
    company: 'SRC Technologies',
    content:
      'NextStage is the most badass tracking system ever. It is game changing for small business defense contractors. I can communicate with my executive team with more accuracy, the reporting is so customizable, and we have financial accountability for our pipeline.',
    headshotImageSource: MargotBlackardHeadshot,
    companyLogo: '',
  },
]

export const TestimonialSection = () => {
  return (
    <div className={style.wrapper}>
      <h1 className={style.sectionHeader}>What others are saying</h1>
      <div className={style.testimonialRow}>
        {testimonials.map((testimonialProps, index) => (
          <Testimonial key={`testimonial_${index}`} {...testimonialProps} />
        ))}
      </div>
    </div>
  )
}
